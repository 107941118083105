import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HighchartComponent from './Highchart';
import { NavigationBox, DailyFitnessActivityHeader, Content, StepAndDistance, CaloriesBurned,  CurrentTime, DateRightArrow, LeftArrow, MyDailyActivityContainer, ContentWrapper} from './styles';
import { ImageUrl } from '../../../utils/constants';
import { commaSeperator, convertMilesToKm, convertMonthFromDate } from '../../../utils/methods';
import InputGoals from '../../InputGoals';
import moment from "moment";
import {getDailyActivityOverview, getMonthlyActivityOverview} from "../../../redux/actions/wellnessDashboardActions";
// import { getUser } from '../../redux/actions';
import {connect} from "react-redux";
// import {LoaderContainer} from "../../Content/styles";
import {ContainerV2} from "../../ContentV2/styles";
// import Loading from "../../Loading";
import Waiting from "../../Waiting";
import {withTranslation} from 'react-i18next';
import LazyImage from '../../common/LazyImage/LazyImage';
import SkeletonLoder from '../../common/skeletonLoder';
const monthlyGoalsData = [
  {text: "Total Steps", key: "monthly_steps"},
  {text: "Calories Burned", key: "monthly_calories"},
  {text: "Total Distance", key: "monthly_distance"}
];
class DailyFitnessActivity extends Component {
  constructor() {
    super();
    this.state = {
      showInputGoalsModal: false,
      modalName: '',
      date: new Date()
    };
  }
  componentDidMount() {
    const { date } = this.state;
    const{challenge, getMonthlyActivityOverview} = this.props;
    const monthStartDate = challenge && moment(date).startOf('month').format('YYYY-MM-DD');
    challenge ? getMonthlyActivityOverview(monthStartDate) : this.getActivityOverviewData(this.getStartDate(date, 'day'), this.getEndDate(date, 'day'));
  }
  showModal = (name) => {
    this.setState({
      modalName: name,
    });
  };

  hideModal = () => {
    this.setState({
      modalName: ''
    });
  };
  getStartDate = (date, value) => this.props.challenge ? moment(date).startOf(value).format('YYYY-MM-DD') : moment(date).startOf(value).format('MM-DD-YYYY');

  getEndDate = (date, value) => moment(date).endOf(value).format('MM-DD-YYYY');

  getActivityOverviewData = (startDate, endDate) => {
    this.props.fetchActivityOverview(startDate, endDate)
  };

  changeToPreviousValue = (value) => {
    const {user} = this.props;
    const registrationDateString = user && user.created_at ? moment(user.created_at).format("MM/DD/YYYY") : "NA";
    const registrationDate = registrationDateString !== "NA" ? moment(registrationDateString, "MM/DD/YYYY").toDate() : new Date();

    if (moment(this.state.date).subtract(1, value).toDate() >= registrationDate) {
      const { date } = this.state;
      const {challenge, getMonthlyActivityOverview} = this.props;
      this.setState({
        date: moment(date).subtract(1, value)
      }, () => {
        challenge ? getMonthlyActivityOverview(this.getStartDate(this.state.date, value)) : this.getActivityOverviewData(this.getStartDate(this.state.date, value), this.getEndDate(this.state.date, value));
      });
    }
  };

  changeToNextValue = (value) => {
    const { date } = this.state;
    const {challenge, getMonthlyActivityOverview} = this.props;
    this.setState({
      date: moment(date).add(1, value)
    }, () => {
      challenge ? getMonthlyActivityOverview(this.getStartDate(this.state.date, value)) :  this.getActivityOverviewData(this.getStartDate(this.state.date, value), this.getEndDate(this.state.date, value));
    });
  };
  render() {
    const { modalName, date} = this.state;
    const {SetDailyGoal, inputGoals, activityOverview, activityLoading, challenge, monthlyActivityOverview,t} = this.props;
    const disableRightArrow = moment(date).startOf(challenge ? 'month' : 'day') >= moment().startOf(challenge ? 'month' : 'day');
    const setWaiting = challenge ? !monthlyActivityOverview : !activityOverview;
    if(setWaiting){
      return <Waiting />;
    }
    const monthlyGoals = challenge && monthlyActivityOverview['monthly_goals'];
    const WorkoutData = challenge && monthlyActivityOverview['workout_data'];
    const DistanceUnit = localStorage.getItem('DISTANCE');
    return (
      <ContainerV2 height={challenge ? '250px' : '250px'} >
        <NavigationBox isDailyFitnessActivity>
          <DailyFitnessActivityHeader>
            <CurrentTime>
              <LeftArrow onClick={() => this.changeToPreviousValue(challenge ? 'month' : 'day')} />
              <div>
                <div className="Date">
                  {
                    challenge 
                      ? 
                      `${convertMonthFromDate(moment(date).startOf('Month').format('MMMM, YYYY'), this.props)}`
                      : 
                      ((localStorage.getItem("lang") != "fr") ? convertMonthFromDate(moment(date).startOf('day').format('Do MMMM, YYYY'), this.props) : convertMonthFromDate(moment(date).startOf('day').format('D MMMM YYYY'), this.props)) 
                  }
                </div>
              </div>
              <DateRightArrow onClick={() => this.changeToNextValue(challenge ? 'month' : 'day')} disable={disableRightArrow} />
            </CurrentTime>
          </DailyFitnessActivityHeader>
          {activityLoading ? <SkeletonLoder/> :
            <ContentWrapper>
              {
                <Content>
                  <StepAndDistance left>
                    <LazyImage src={`${ImageUrl}/images/NewDashboardImages/caloriesNew.png`} alt="Footprint"/>
                    <div
                      className="StepsCount">{challenge ? WorkoutData && commaSeperator(parseInt(WorkoutData['calories'] || 0)) : activityOverview && commaSeperator(parseInt(activityOverview['calories'] || 0))}<span> cal</span></div>
                    <div className="TotalSteps">{t("Calories")}</div>
                  </StepAndDistance>
                  <CaloriesBurned>
                    <HighchartComponent id="graph" payload={challenge ? WorkoutData : activityOverview}/>
                  </CaloriesBurned>
                  <StepAndDistance right>
                    <LazyImage style={{width:"27px", height:"37px"}} src={`${ImageUrl}/images/NewDashboardImages/distanceNew.png`} alt="Distance"/>
                    <div
                      className="StepsCount">{challenge ? WorkoutData && `${parseFloat(WorkoutData['distance'] || 0.00).toFixed(1)} miles` :DistanceUnit === "KILOMETER"? activityOverview && `${convertMilesToKm(activityOverview['distance'] || 0.0)} km` : activityOverview && `${parseFloat(activityOverview['distance'] || 0.00).toFixed(1)} mi`}</div>
                    <div className="TotalSteps">{t("Distance")}</div>
                  </StepAndDistance>
                </Content>
              }
              {
                challenge ? <MyDailyActivityContainer>
                  <div>
                    {t("My Daily Activity Goals")} 
                  </div>
                  <div>
                    {monthlyGoalsData.map((data, index) => (<div key={index}>
                      <div>
                        {data.text === 'Total Distance' ? `${monthlyGoals[data.key]} mi` : commaSeperator(parseInt(monthlyGoals[data.key]))}
                      </div>
                      <div>
                        {data.text}
                      </div>
                    </div>))
                    }
                  </div>
                </MyDailyActivityContainer> : null
              }
            </ContentWrapper>
          }
        </NavigationBox>
        <InputGoals SetDailyGoal={SetDailyGoal} showModal={modalName === 'inputGoal'} onHide={this.hideModal} inputGoals={inputGoals} />
      </ContainerV2>
    )
  }
}

DailyFitnessActivity.propTypes = {
  SetDailyGoal: PropTypes.func,
  inputGoals: PropTypes.object,
  activityOverview: PropTypes.object,
  activityLoading: PropTypes.bool.isRequired,
  fetchActivityOverview: PropTypes.func.isRequired,
  challenge: PropTypes.number,
  getMonthlyActivityOverview: PropTypes.func,
  monthlyActivityOverview: PropTypes.object,
  t: PropTypes.func,
  user: PropTypes.object

};

const mapStateToProps = (state) => ({
  user: state.profileData.user,
  activityOverview: state.wellnessDashboard.dailyActivityOverview,
  activityLoading: state.wellnessDashboard.activityLoading,
  monthlyActivityOverview: state.wellnessDashboard.monthlyActivityOverview
});

const mapDispatchToProps = (dispatch) => ({
  fetchActivityOverview: (startDate, endDate) => dispatch(getDailyActivityOverview(startDate, endDate)),
  getMonthlyActivityOverview: (date) => dispatch(getMonthlyActivityOverview(date))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DailyFitnessActivity));
