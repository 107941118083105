import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  DailySummaryDiv, Top, BottomContainer, CaloriesContainer, StepsContainer } from './styles';
import Thumbnail from '../../Thumbnail';
import { getOrdinalNum } from '../../../utils/methods';
import { imgPath } from '../../../utils/constants';

class DailySummary extends Component {
  constructor() {
    super();
    this.state = {
      renderTip: true,
    };
  }

  toggleViews = (value) => {
    this.setState({
      renderTip: value
    });
  }

  render() {
    const { fullMonth, day, userMonthlyDetails } = this.props;
    return (
      <DailySummaryDiv>
        <Top>
          <Thumbnail
            src={`${imgPath}/NewDashboardImages/DailySummary.png`}
            alt={"Image"}
          />
          <div className="overlay"/>
          <div className="calendarBox">
            <div className="calendarDate">
              <img src="/public/images/NewDashboardImages/calendar.png"/>
              <span>{fullMonth}</span>
            </div>
            <div className="dateContainer">
              <div>
                <span>Day</span>
                <span className="date">{day}</span>
              </div>
              <span>{getOrdinalNum(day)}</span>
            </div>
          </div>
        </Top>
        <BottomContainer>
          <CaloriesContainer>
            {(userMonthlyDetails.monthly_calories) >= 1000 ?
              <div>
                <div>{`${(userMonthlyDetails.monthly_calories / 1000).toFixed(1)}k`}</div>
                <span>{"Calories"}</span>
              </div> :
              <div>
                <div>{userMonthlyDetails.monthly_calories}</div>
                <span>{"Calories"}</span>
              </div>
            }
            <Thumbnail
              src={`${imgPath}/NewDashboardImages/arrow.png`}
              alt={"Image"}
            />
            <div className="caloriRemaining">
              {(userMonthlyDetails.remaining_calories) >= 1000 ?
                <div>
                  {`${(userMonthlyDetails.remaining_calories / 1000).toFixed(1)}k`}
                  <span>Cal</span>
                </div> :
                <div>
                  {userMonthlyDetails.remaining_calories}
                  <span>Cal</span>
                </div>
              }
              <span>Total Remaining</span>
            </div>
          </CaloriesContainer>
          <StepsContainer>
            <div>
              <div>
                {`${userMonthlyDetails.monthly_steps/1000}k`}
                <span>mi</span>
              </div>
              <span>{"Total Steps"}</span>
            </div>
            <Thumbnail
              src={`${imgPath}/NewDashboardImages/arrow.png`}
              alt={"Image"}
            />
            <div className="caloriRemaining">
              <div>
                {`${userMonthlyDetails.remaining_steps/1000}k`}
                <span>mi</span>
              </div>
              <div>Total Steps Remaining</div>
            </div>
          </StepsContainer>
        </BottomContainer>
      </DailySummaryDiv>
    )
  }
}

DailySummary.propTypes = {
  fullMonth: PropTypes.string,
  day: PropTypes.number,
  userMonthlyDetails: PropTypes.object
}

export default DailySummary;
